export const theme = {
  background: '#FFFFFF',
  onBackground: '#363740',
  onBackgroundLight: '#A4A6B3',
  primary: '#7059ff',
  primaryFaded: '#8f74ff8a',
  primaryDark: '#30208fe3',
  surface: '#363740',
  onSurface: '#838590',
  onSurfacePrimary: '#DDE2FF',
  onSurfaceLight: '#9ea1b314',
  surfaceTwo: '#f0f2f9',
  onSurfaceTwo: '#707178',
  onSurfaceTwoPrimary: '#3853ff66',
  onSurfaceTwoLight: '#70717814',
  onSurfaceThree: '#f0f2f973',
  secondary: '#FFC145',
  danger: '#FF6B6C',
  onDanger: '#F7F8FC',
  codeBackground: ' rgba(9, 30, 66, 0.08)',
  borderColor: '#e0e1e5',
  spacing: '10px',
  spacingLarge: '20px',
  spacingExtraLarge: '60px',
  phone: `(max-width: 425px)`,
  tablet: `(max-width: 768px)`,
  desktop: `(max-width: 2560px)`,
};
